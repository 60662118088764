// Анимация метрцания
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    animation-fill-mode: forwards;
  }
}

// Анимация всплытия
@keyframes upDown {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0);
  }
}
// Анимация всплытия и обратное всплытие
@keyframes upDownReverse {
  0% {
    transform: translateY(1000px);
  }
  10% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1000px);
  }
}

// Анимация перемещения
@keyframes movementUp {
  0% {
    transform: translateY(1000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes movementDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1000px);
  }
}
