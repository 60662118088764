@import "../Global/index.scss";

.header {
  padding: 30px 60px;
  background-color: darken($accent, 8%);
  // background-image: url("../../../public/img/bg.png");
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  text-align: right;
  z-index: 999;
}

.data {
  display: flex;
  flex-direction: column;
  @include Myriad-Pro;
}

.dateTime {
  font-size: 26px;
  @include Gotham-Pro;
  font-weight: bold;
}

.dateDay {
  font-size: 17px;
  line-height: 24px;
}

.buttonMainOffset {
  margin: 20px;
}

.appClock {
  font-size: 37px;
}

@media print {
  .header {
    display: none;
  }
}
