@import "../../Global/index.scss";

:global(.container) {
  max-width: none !important;
}

.containerBody {
  display: grid;
  max-width: none !important;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.splitLeft,
.splitRight {
  overflow-y: auto; // Включаем скролл по вертикали при необходимости
  overflow-x: hidden; // Скрываем горизонтальный скролл
  max-height: calc(100vh - 120px); // Вычитаем отступ сверху
}

.contentWrap {
  text-align: center;
}


.left {
  left: 0;
  background-color: $white;
}

.right {
  right: 0;
  background-color: $accent;
}

.centered img {
  width: 150px;
  border-radius: 50%;
}

.title {
  font-size: 70px;
  line-height: 36px;
  @include Gotham-Pro;
}

.arrow {
  vertical-align: middle;
}


.titleAccent {
  color: $white;
}

.titleWhite {
  color: $accent;
}

.smallSize{
  font-size: 32px;
  line-height: 50px;
}

.list_query {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;

  li {
    list-style-type: none;
    color: #58595B;
    @include Gotham-Pro;
    font-weight: bold;
    font-size: 42px;
    line-height: 70px;
    span {
      color: $hover;
      @include Gotham-Pro-Normal;
      font-weight: 900;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes blinkThreeTimes {
  0%, 10%, 100% { opacity: 1; }
  20%, 50% { opacity: 0; }
  30%, 60% { opacity: 1; }
  40%, 70% { opacity: 0; }
  80% { opacity: 1; }
}

.blinkThreeTimes {
  animation: blinkThreeTimes 3s linear;
}

.list_process {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;

  li {
    list-style-type: none;
    color: $white;
    @include Gotham-Pro;
    font-weight: bold;
    font-size: 42px;
    line-height: 70px;

    span {
      color: $hover;
      @include Gotham-Pro-Normal;
      font-weight: 900;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list_process li:only-child {
  text-align: center;
}

.position {
  position: absolute;
  left: 25%;
  bottom: 0;
}

.buttonPlay {
  display: none;
}
