.container {
  margin: 0 auto;
  max-width: 1110px;
}

.row {
  padding: 0 50px;
}

.container-fluide-grid{
  display: flex;
  align-items: center;
  justify-content: center;
}

