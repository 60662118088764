@import "../../Global/index.scss";

.contentWrap {
  text-align: center;
}

.split {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  margin-top: 120px;
}

.left {
  left: 0;
  background-color: $accent;
}

.right {
  right: 0;
  background-color: $white;
}

.centered img {
  width: 150px;
  border-radius: 50%;
}

.title {
  font-size: 80px;
  line-height: 36px;
  @include Gotham-Pro;
}

.titleAccent {
  color: $accent;
}

.titleWhite {
  color: $white;
}

.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100vh - 200px);
}

.list li {
  list-style-type: none;
  color: $gray-dark;
  @include Gotham-Pro;
  font-weight: bold;
  font-size: 60px;
  line-height: 90px;
  span {
    color: $hover;
    @include Gotham-Pro-Normal;
    font-weight: 900;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.position {
  position: absolute;
  left: 25%;
  bottom: 0;
}

.buttonPlay {
  display: none;
}
