@import "../../Global/index.scss";

.secondaryButton {
    display: block;
    text-align: center;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    padding: 23px;
    color: $text-color;
    border-radius: $border-radius;
    @extend .transition;
    font-size: 22px;
    @include Gotham-Pro-Normal;
    background-color: $hover;
    color: $white;
    width: 120px;
    &:hover,
    &:active,
    &:focus {
        background-color: $hover;
        color: $white;
    }
}

