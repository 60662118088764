@import "./vars";


* {
  box-sizing: border-box;
}

ul,
li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  @include Gotham-Pro-Normal;
}

body {
  font-size: 16px;
  min-width: 320px;
  line-height: 25px;
  overflow: hidden !important;
  opacity: 1;
  background-image: url(../../../public/img/bg.svg);
  background-repeat: no-repeat;
  @include Myriad-Pro;
  background-size: cover;
  color: $text-color;
  user-select: none; 
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}

@media (max-width: 576px) {
}
