@font-face {
    font-family: "Gotham Pro";
    src: url("../../fonts/ofont.ru_Gotham Pro.ttf");
}

@font-face {
    font-family: "Myriad Pro";
    src: url("../../fonts/MyriadProRegular.ttf");
}

@font-face {
    font-family: "Gotham Pro Normal";
    src: url("../../fonts/gothampro.ttf");
}