@import "../Global/index.scss";

.coupon {
  background-color: #fefefe;
  width: 470px;
  height: 310px;
  @extend .shadow;
  position: absolute;
  left: calc(50% - (470px / 2));
  bottom: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: -7px;
    width: 100%;
    height: 10px;
    background-image: url(../../../public/img/triangle.png);
    background-repeat: repeat;
    z-index: 2;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    height: 10px;
    background-image: url(../../../public/img/triangle.png);
    background-repeat: repeat;
    background-repeat: repeat;
    transform: rotate(180deg);
    z-index: 2;
  }
  h2 {
    @include Gotham-Pro;
  }
}

.movementUp {
  animation: movementUp 1s forwards;
}
.movementDown {
  animation: movementDown 1s forwards;
}

.couponContent {
  text-align: center;
}

.number {
  color: $accent;
  @include Gotham-Pro;
  font-size: 110px;
  line-height: 144px;
}

.date {
  display: flex;
  justify-content: center;
  color: $gray-dark;
  @include Gotham-Pro;
}

.dateTop {
  margin-top: 66px;
}

.dateMonth {
  margin-right: 80px;
}

.scrollHidden {
  overflow-y: hidden;
}

.window {
  color: $hover;
  @include Gotham-Pro-Normal;
  font-size: 48px;
  font-weight: bold;
  margin-top: 24px;
}

.element {
  position: absolute;
  left: 50%;
}
