@import "../../Global/index.scss";

.containerCoupon {
    margin: 0 auto;
    max-width: 1110px;
    height: 100vh;
    position: relative;
}

.contentWrap{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 80px;
}

.womanImg{
    animation: fadeIn 3s 1s;	
    animation-fill-mode: both;
}

.couponWrap{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 80px;
}


.couponDescription{
    @include Myriad-Pro;
    font-weight: bold;
    text-align: center;
    display: flex;
}

.cuponAnimation{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    animation: upDown 2s;
    animation-fill-mode: both;
}

.print{
    display: none;
}

@media print{
    .noPrint{
        display: none;
    }

    .print{
        display: block;
    }

    @page {
        size: auto;
        margin: 0;
    }
}