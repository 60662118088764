@import "../Global/index.scss";

.footer {
  position: absolute;
  bottom: 0;
  background-color: #2e8899;
  padding: 30px;
  color: #fff;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.gerland {
  display: block;
  height: 108px;
}

.gerlandPosition {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 9999;
}

.position {
  float: left;
  margin: 0;
  padding: 0;
  margin-left: 35px;
}

.footerLoader {
  position: absolute;
  right: 260px;
  margin-top: -25px;
  z-index: 999;
}

.preloaderPosition {
  position: absolute;
  right: 200px;
  bottom: 100px;
}

.loadingSpinner {
  position: absolute;
  right: 200px;
  bottom: 200px;
}

.dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -140px;
  z-index: 9999 !important;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $hover;
  margin-left: 20px;
  display: flex;
  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(8) {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
}

@media print {
  .footer {
    display: none;
  }
}
