@import "../../Global/index.scss";

.button {
  text-align: center;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}

.buttonMain {
  background-color: $accent;
  color: #fff;
  border-radius: 3px;
  font-size: 32px;
  margin-right: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  &:hover,
  &:active,
  &:focus {
    background-color: darken($accent, 5%);
    @extend .transition;
  }
}

.buttonBottom {
  margin-bottom: 12px;
}

.animationButton {
  // animation: movement 7s infinite alternate ease-in-out;
  animation-fill-mode: both;
  $itemDelay: 1s;
  @for $i from 0 through 8 {
    animation-fill-mode: both;
    &:nth-child(#{$i}) {
      animation-delay: $itemDelay * $i;
      animation-fill-mode: both;
      animation-fill-mode: forwards;
    }
  }
}
