@import "../Global/index.scss";

.coupon {
  background-color: #fefefe;
  width: 470px;
  height: 510px;
  @extend .shadow;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: -7px;
    width: 100%;
    height: 10px;
    background-image: url(../../../public/img/triangle.png);
    background-repeat: repeat;
    z-index: 2;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 10px;
    background-image: url(../../../public/img/triangle.png);
    background-repeat: repeat;
    background-repeat: repeat;
    transform: rotate(180deg);
    z-index: 2;
  }
  h2 {
    @include Gotham-Pro;
  }
}

.couponContent {
  text-align: center;
  padding: 40px;
}

.cupunDirection {
  font-weight: bold;
  font-size: 20px;
}

.cupunDirectionTop {
  margin-top: 20px;
}

.number {
  color: $accent;
  @include Gotham-Pro;
  font-size: 150px;
  line-height: 144px;
}

.date {
  display: flex;
  justify-content: center;
  color: $gray-dark;
  @include Gotham-Pro;
}

.dateTop {
  margin-top: 66px;
}

.dateMonth {
  margin-right: 80px;
}

@media print {
  .printCouponWrap {
    position: absolute;
    top: 0;
    left: 0;
  }

  .printCoupon {
    width: 280px;
    height: 300px;
    text-align: center;
  }

  .printQueue {
    font-family: "Gotham Pro";
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-right: 5px;
  }

  .PrintNumber {
    @include Gotham-Pro;
    font-size: 75px;
    line-height: 144px;
    color: #000;
    margin: 0;
    padding: 0;
    margin-top: -20px;
  }

  .printDescription {
    font-family: "Gotham Pro";
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: -25px;
    margin-bottom: 10px;
  }

  .printFooter {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .printFooterTop {
    margin-top: 10px;
  }

  .printDataRight {
    margin-right: 80px;
  }
}
