$accent: #00a7b5;
$text-color: #171d20;
$white: #fff;
$border-radius: 3px;
$hover: #F08646;
$gray-dark: #58595B;

.transition {
  transition: all 0.25s ease;
}

.shadow{
  box-shadow: 0px 4px 46px rgba(37, 154, 164, 0.26);
}

@mixin Gotham-Pro {
  font-family: "Gotham Pro";
  src: url("../../fonts/ofont.ru_Gotham Pro.ttf");
}

@mixin Myriad-Pro {
  font-family: "Myriad Pro";
  src: url("../../fonts/MyriadProRegular.ttf");
}

@mixin Gotham-Pro-Normal {
  font-family: "Gotham Pro Normal";
  src: url("../../fonts/gothampro.ttf");
  font-weight: normal;
}

.button {
  border: none;
  border-radius: $border-radius;
  padding: 12px 18px;
  cursor: pointer;
  @extend .transition;
}

.text-right{
  text-align: right;
}
