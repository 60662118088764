@import "../Global/index.scss";

.title {
  text-align: center;
}

.titleTop {
  margin-top: 76px;
}

.titleBottom {
  margin-bottom: 24px;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 980px;
  min-height: 600px;
  //max-height: 920px;
  margin: 0 auto;
  overflow-wrap: break-word;

  &.doubleColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 55px;
    max-width: 1360px;
    min-height: unset;
  }
}

.buttonWrapTop{
  margin-top: 55px;
}

.animationButton {
  width: 100%;
  animation: movement 1s infinite alternate ease-in-out;
}

.positionBlock{
  width: 980px;
  margin: 0 auto;
}