@import "../Global/index.scss";

.dotWrap{
  position: absolute;
  right: 60px;
  bottom: 60px;
  z-index: 9999;
}

.dotsBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 90px;
  position: relative;
}

.rows {
  display: flex;
  align-items: center;
  width: 100%;
}

.rowBetween {justify-content: space-between;}
.rowAround {
  justify-content: space-around;
  padding: 0 calc(20px / 2);
  box-sizing: border-box;
  }

.dot {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.dotAnimation{
  animation: blink 2s linear infinite;
}

.dotAccent {
  background-color: $hover;
}

.dotWhite{
  background-color: $white;
}

.one { animation-delay: .15s; }
.two { animation-delay: .25s; }
.three { animation-delay: .37s; }
.four { animation-delay: .5s; }
.five { animation-delay: .75s; }
.six { animation-delay: 1s; }
.seven { animation-delay: 1.25s; }
.eight { animation-delay: 1.5s; }

@keyframes blink {
  0%, 20% {
    scale: 1;
  }
  10% {
    scale: 1.6;
  }
}