@import "../Global/index.scss";

.header {
  padding: 0 60px;
  background-color: darken($accent, 8%);
  // background-image: url("../../../public/img/bg.png");
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  text-align: right;
  height: 120px;
  z-index: 999;
}

.textDescription{
  line-height: 32px;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
}

.logo{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.data {
  display: flex;
  flex-direction: column;
  @include Myriad-Pro;
}

.dateTime {
  font-size: 26px;
  @include Gotham-Pro;
  font-weight: bold;
}

.dateDay {
  font-size: 32px;
  margin-top: 20px;
  font-weight: bold;
  line-height: 24px;
}

.buttonMainOffset {
  margin: 20px;
}

.appClock {
  font-size: 37px;
}

@media print {
  .header {
    display: none;
  }
}
